<template>
  <div>
    <v-app>
      <nav class="navbar relative d-block">
        <ul
          class="navbar-ul mt-2 d-flex text-center flex-row-reverse align-center justify-sm-start justify-center"
        >
          <li class="align-center sign ml-n12 cursor-pointer">
            <img
              width="350px"
              alt="Mostafa Rahmati"
              title="mostafa rahmati"
              src="./assets/signature.webp"
              @click.stop="goToHomePage"
            />
          </li>
          <li class="navbar-li title">
            <a
              class="navbar-links ma-5 text-decoration-none moon"
              title="light mode"
              rel="noreferrer"
              target="_blank"
              href="https://www.google.com/search?rlz=1C1GCEA_enIR851IR851&sxsrf=ALeKk031qBJij6G4eVwNMfgeKQmJGPCTNg%3A1594233001093&ei=qRAGX4OoBc3VgQby8aPgAg&q=help+i+don%27t+like+dark+mode+i+think+i+need+therapy&oq=help+i+don%27t+like+dark+mode+i+think+i+need+thera&gs_lcp=CgZwc3ktYWIQAxgAMgcIIRAKEKABOgQIABBHOgQIIRAVOgUIIRCgAToICCEQFhAdEB5Q3oQBWMagAmCPqQJoDXABeAGAAfEEiAGqRZIBDDAuMS4zMC4xLjEuMZgBAKABAaoBB2d3cy13aXo&sclient=psy-ab"
            >
              <v-icon>$moon</v-icon>
            </a>
          </li>
          <li class="navbar-li">
            <router-link
              exact-active-class="active"
              class="navbar-links ma-5 text-decoration-none custom-underline"
              title="About me"
              to="/about"
              >About me</router-link
            >
          </li>
          <li class="navbar-li">
            <router-link
              exact-active-class="active"
              class="navbar-links ma-5 text-decoration-none custom-underline"
              title="Contact me"
              to="/contact-me"
              >contact me</router-link
            >
          </li>
          <li class="navbar-li">
            <router-link
              exact-active-class="active"
              class="navbar-links ma-5 text-decoration-none custom-underline"
              title="Home page of Mostafa Rahmati personal website"
              to="/"
              >Home</router-link
            >
          </li>
        </ul>
      </nav>
      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  methods: {
    goToHomePage() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}
svg {
  fill: currentColor;
}
.navbar {
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
}
.navbar-ul {
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0 !important;
}
.navbar-links {
  color: white !important;
}
.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: white;
  text-decoration: none;
}
.custom-underline {
  position: relative;
  padding: {
    bottom: 13px;
  }
  text-align: center;
}

.custom-underline::after {
  content: "";
  position: absolute;
}

.navbar-li .custom-underline::after {
  top: 85%;
  height: 2px;
  width: 0%;
  left: 5%;
  transition: 0.2s ease-in-out all 0.2s;
}

.navbar-li:hover .custom-underline::after {
  background-color: rgba(255, 255, 255, 0.685);
  width: 90%;
  transition: 0.2s ease-in-out all;
}
.active::after {
  content: "" !important;
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.685) !important;
  width: 90% !important;
}
.sign {
  position: absolute;
  left: 1px;
  margin-top: 8px;
  @media screen and (max-width: 650px) {
    display: none;
  }
}
.moon {
  @media screen and (max-width: 500px) {
    display: none;
  }
}
.up-btn {
  position: fixed !important;
  right: 1rem;
  bottom: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
