<template>
  <v-app dark class="text-center">
    <div
      class="gif d-flex flex-column justify-center align-center text-center mt-12"
    >
      <img
        class="mb-5"
        width="500"
        height="500"
        src="../assets/notFound.webp"
        alt="Not found"
      />
      What the hell are you looking for?
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style></style>
