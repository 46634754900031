<template>
  <div>
    <v-dialog max-width="750" v-model="dialog">
      <v-card
        min-width="300"
        class="dialog-card d-flex px-6 pt-6 overflow-hidden"
        rounded
      >
        <div class="text-left dialog-text">
          <v-icon @click="closeDialog()">$close</v-icon>
          <v-card-title class="mb-2">{{ dialogHeader }}</v-card-title>
          <v-card-subtitle>{{ dialogText }}</v-card-subtitle>
          <v-divider class="mx-6"></v-divider>
          <v-card-text class="text-left">
            <ul>
              <li class="mb-3" :key="index" v-for="(item, index) in techs">
                <span class="font-weight-bold">{{ item.name }}</span> -
                <span class="subtitle-2" v-html="item.disc"></span>
              </li>
            </ul>
            <v-divider class="mx-1"></v-divider>
            <v-btn
              class="mt-4 ml-2"
              icon
              link
              :href="gitLink"
              rel="noreferrer noopener"
              target="_blank"
            >
              <v-icon>$git</v-icon>
            </v-btn>
            <v-btn
              class="mt-4 ml-2"
              icon
              link
              :href="websiteLink"
              rel="noreferrer noopener"
              target="_blank"
            >
              <v-icon>$link</v-icon>
            </v-btn>
          </v-card-text>
        </div>
        <div class="dialog-img">
          <v-img eager contain src="../assets/code_undraw.svg"></v-img>
        </div>
      </v-card>
    </v-dialog>
    <section id="about-me" class="px-5 pb-12 mt-12">
      <v-row class="text-center">
        <v-col class="title">
          Hi, I'm Mostafa Rahmati - a full-stack developer
          <span>💻</span>
          and a former baby
          <br />
          <v-row class="d-flex justify-center my-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  depressed
                  link
                  name="linkedin"
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  href="https://www.linkedin.com/in/mostafa-rahmati-717470180/"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="linkedin"
                >
                  <v-icon>$linkedin</v-icon>
                </v-btn>
              </template>
              <span>linkedin</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  depressed
                  link
                  name="gitlab"
                  v-bind="attrs"
                  v-on="on"
                  href="https://gitlab.com/mostafa7904"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="gitlab"
                >
                  <v-icon>$git</v-icon>
                </v-btn>
              </template>
              <span>Gitlab</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-4"
                  icon
                  small
                  depressed
                  link
                  name="stackoverflow"
                  v-bind="attrs"
                  v-on="on"
                  href="https://stackoverflow.com/users/11084093/mostafa-rahmati"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="stackoverflow"
                >
                  <v-icon>$stackoverflow</v-icon>
                </v-btn>
              </template>
              <span>Stackoverflow</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  depressed
                  link
                  name="github"
                  v-bind="attrs"
                  v-on="on"
                  href="https://github.com/mostafa7904"
                  rel="noreferrer noopener"
                  target="_blank"
                  title="github"
                >
                  <v-icon>$github</v-icon>
                </v-btn>
              </template>
              <span>Github</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <section id="showcase" class="mt-12">
      <div class="text-center mb-1 title">Stuff i made</div>
      <div class="text-center mb-4 subtitle-1">
        Here are some websites i made for fun. Enjoy :)
      </div>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="6"
          class="cards"
          v-for="(showcase, index) in showcases"
          :key="index"
        >
          <v-img
            contain
            class="mx-auto work-img"
            max-width="100%"
            :src="getImage(showcase)"
          ></v-img>
          <v-sheet
            shaped
            elevation="12"
            class="mx-auto v-sheet--offset"
            max-width="350"
            min-width="calc(100% - 90px)"
          >
            <div class="text-center pt-3">
              <div class="title">{{ showcase.title }}</div>
              <div class="subtitle-2 mb-6">{{ showcase.subtitle }}</div>
              <div class="d-flex justify-space-between px-6">
                <div
                  class="subtitle"
                  v-for="(hashtag, idx) in showcase.hashtags"
                  :key="idx"
                >
                  #{{ hashtag }}
                </div>
              </div>
            </div>
            <v-divider class="my-4 mx-6"></v-divider>
            <div class="text-center pb-3">
              <v-btn
                title="link"
                :name="'link-' + showcase.title"
                link
                class
                icon
                target="_blank"
                rel="noreferrer noopener"
                small
                depressed
                :href="showcase.siteLink"
              >
                <v-icon>$link</v-icon>
              </v-btn>
              <v-btn
                :name="'link-' + showcase.title"
                @click="openDialog(showcase)"
                title="technologies used"
                class
                icon
                small
                depressed
              >
                <v-icon>$techs</v-icon>
              </v-btn>
            </div>
          </v-sheet>
        </v-col>

        <v-alert class="mx-8" color="#F27E63" outlined dense shaped>
          Ps.
          <br />The icd website is in production and there is no point in
          putting the link to it cause you need authentication. same thing goes
          for cng admin panel but i kept a prototype of that one for myself
          cause it's cute.
        </v-alert>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapState({
      showcases: (state) => state.showcases,
    }),
  },
  data() {
    return {
      dialogText: "",
      dialogHeader: "",
      techs: [],
      gitLink: "",
      websiteLink: "",
      dialog: false,
    };
  },
  methods: {
    getImage(showcase) {
      const path = require("../" + showcase.image);
      return path;
    },
    openDialog({ title, subtitle, techs, siteLink, gitLink }) {
      this.dialog = true;
      this.dialogText = subtitle;
      this.dialogHeader = title;
      this.websiteLink = siteLink;
      this.gitLink = gitLink;
      this.techs = techs;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.gif-wrapper {
  width: 200px;
  height: 120px;
  z-index: 100;
  position: fixed;
  & div {
    -webkit-box-shadow: 6px 6px 18px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 6px 18px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 18px -1px rgba(0, 0, 0, 0.75);
  }
}
.cards {
  .v-sheet--offset {
    position: relative;
    transition: 0.2s ease-in-out;
    top: -35px;
  }
  &:hover {
    .v-sheet--offset {
      top: -20px;
    }
  }
}
.work-img {
  border-radius: 24px;
}
.subtitle {
  font-size: 13px;
}
.dialog-img {
  width: 50%;
  height: 100%;
  flex: 0.5;
  float: right;
  @media screen and (max-width: 600px) {
    display: none;
    flex: 0;
  }
}
.dialog-text {
  width: 100%;
  height: 100%;
  float: left;
  flex: 0.5;
  @media screen and (max-width: 600px) {
    flex: 1;
  }
}
</style>
