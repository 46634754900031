import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Mostafa Rahmati | personal website",
    },
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About Mostafa Rahmati",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact-me",
    name: "Contact me",
    meta: {
      title: "Contact me | Contact Mostafa Rahmati",
    },
    component: () =>
      import(/* webpackChunkName: "contact-me" */ "../views/Contact-me.vue"),
  },
  {
    path: "**",
    name: "Page-Not-Found",
    meta: {
      title: "Page not found",
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/notFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title ? to.meta.title : "Mostafa rahmati";
  next();
});
export default router;
