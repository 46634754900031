<template>
  <div>
    <div class="intro pa-6">
      <h2>Damn, a whole page just about me :)</h2>
      <div class="my-12">
        Hey there, I'm Mostafa and I'm a front-end developer with some
        experience in back-end. I started learning JavaScript about 6 years ago
        and I've been working as a front-end developer for almost 5 years now. I
        mostly work with JavaScript, but I like working with GoLang sometimes
        (makes me feel cool).
      </div>
    </div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>My skills</v-card-title>
          <v-card-text>
            <v-progress-linear value="95" color="#BF1736" height="25" rounded>
              <strong>Vue.js / Nuxt.js</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="90" color="#D95284" height="25" rounded>
              <strong>Node.js / Express.js</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="85" color="#80A7A2" height="25" rounded>
              <strong>TypeScript</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="80" color="#044BD9" height="25" rounded>
              <strong>React.js / Next.js</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="80" color="#F3764D" height="25" rounded>
              <strong>MySQL / Postgress</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="80" color="secondary" height="25" rounded>
              <strong>Git</strong>
            </v-progress-linear>
            <br />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Languages</v-card-title>
          <v-card-text>
            <v-progress-linear value="100" color="#0433BF" height="25" rounded>
              <strong>Persian</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="95" color="#0B9ED9" height="25" rounded>
              <strong>English</strong>
            </v-progress-linear>
            <br />
            <v-progress-linear value="40" color="#BF1736" height="25" rounded>
              <strong>French</strong>
            </v-progress-linear>
            <br />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col>
        <v-card>
          <v-card-title>
            Exprience
            <a href="/resume.pdf" target="_blank" class="ml-2">
              (My full resume)
            </a>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="(item, index) in items">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar rounded :color="item.color">
                    {{ item.avatar }}
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data: () => ({
    items: [
      { header: "I was lucky enough to work with these companies" },
      {
        avatar: "O",
        title:
          "OTeacher | <span class='text--secondary'>2022 - peresent</span>",
        subtitle: `<span class='text--primary'>Front-end</span>
           &mdash; Working on OTeacher. An E-learning website for learning foreign languages. Working with Vue and Nuxt.`,
        color: "#36c8f3",
      },
      { divider: true, inset: true },
      {
        avatar: "I",
        title: "Ideato | <span class='text--secondary'>2021 - peresent</span>",
        subtitle: `<span class='text--primary'>Full-stack</span>
           &mdash; Worked on my own idea called ideato. making both the back-end and front-end. Also made a CDN for ideato,
           a messaging microservice, security of the website and a blog written in Next.js.`,
        color: "#944df1",
      },
      { divider: true, inset: true },
      {
        avatar: "D",
        title:
          "Dealer's cafe | <span class='text--secondary'>2020 - 2021</span>",
        subtitle: `<span class='text--primary'>Front end</span>
           &mdash; Worked as a team leader and a developer, Making a crypto currency broker with a social  media built in it. (Project was abandoned)`,
        color: "#400036",
      },
      { divider: true, inset: true },
      {
        avatar: "A",
        title: "Akointech | <span class='text--secondary'>2018 - 2020</span>",
        subtitle: `<span class='text--primary'>Full-stack</span>
           &mdash; My responsibilities included building full-stack apps with node.js and JavaScript framewrorks (Vue, Nuxt, React, Angular, Ionic)
           I also worked remotly with another company in Australia as a full-stack developer while i was here.`,
        color: "#6805F2",
      },
      { divider: true, inset: true },
      {
        avatar: "F",
        title: "Freelance | <span class='text--secondary'>2018 - 2018</span>",
        subtitle:
          "<span class='text--primary'>frontend developer</span> &mdash; Building the website for Green line group and other clients.",
        color: "#FF4858",
      },
    ],
  }),
};
</script>
