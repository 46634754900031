<template>
  <v-card
    min-width="300"
    max-width="500"
    min-height="500"
    class="d-flex flex-column justify-center text-center mx-auto my-12"
    color="transparent"
    elevation="0"
  >
    <h2 class="title mb-1">Contact me</h2>
    <div class="subtitle-2 d-flex justify-center mb-3">
      <v-btn
        text
        title="twitter"
        icon
        color="white"
        link
        rel="noreferrer"
        href="https://twitter.com/mosy7904"
      >
        <v-icon>$twitter</v-icon>
      </v-btn>

      <v-btn
        title="Email"
        rel="noreferrer"
        text
        icon
        target="_blank"
        color="white"
        href="mailto:mostafa7904@gmail.com?subject=Hello Mostafa I want to work with u :)&body=Tell me what you want"
      >
        <v-icon>$email</v-icon>
      </v-btn>
    </div>
    <form @submit.prevent="submit()">
      <v-col cols="12">
        <v-text-field
          type="text"
          label="Your name"
          outlined
          v-model="name"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="email"
          type="text"
          label="Your Email"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="text"
          label="Your message"
          type="text"
          outlined
        ></v-textarea>
      </v-col>
      <v-btn outlined type="submit" color="#F3764D" class="submit-btn">
        Send
      </v-btn>
    </form>

    <v-dialog
      v-model="dialog"
      max-width="300"
      overlay-color="black"
      overlay-opacity="1"
    >
      <v-btn class="mb-5" icon @click="dialog = false">
        <v-icon>$close</v-icon>
      </v-btn>
      <div class="title text-center font-weight-bold mb-3">{{ status }}</div>
      <div class="text-center mb-5">{{ message }}</div>
      <div class="gif">
        <img :src="image" alt="Call you" width="300px" height="100%" />
      </div>

      <v-btn @click="dialog = false" color="green lighten-2">I guess ok!</v-btn>
    </v-dialog>
  </v-card>
</template>

<script>
/* 
  My god this code is ugly.
  I wrote this piece of crap years ago, I was still a baby programmer
*/
export default {
  name: "ContactMe",
  data() {
    return {
      name: null,
      email: null,
      text: null,
      status: null,
      message: null,
      image: null,
      dialog: false,
    };
  },
  methods: {
    submit() {
      // No firebase yet... so All requests fail
      this.status = "Something went wrong 😕";
      this.image = require("@/assets/failed.webp");
      this.dialog = true;
    },
  },
};
</script>
