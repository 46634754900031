import Vue from "vue";
import Vuex from "vuex";
const showcases = require("./showcases.json");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showcases,
  },
});
