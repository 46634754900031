import Vue from "vue";
import Vuetify from "vuetify/lib";
import email from "../components/email.vue";
import twitter from "../components/twitter.vue";
import link from "../components/link.vue";
import techs from "../components/techs.vue";
import git from "../components/git.vue";
import stackoverflow from "../components/stackoverflow.vue";
import close from "../components/close.vue";
import moon from "../components/moon.vue";
import github from "../components/github.vue";
import blog from "../components/blog.vue";
import linkedin from "../components/linkedin.vue";
import minifyTheme from "minify-css-string";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      minifyTheme,
      themeCache: {
        get: (key) => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      },
    },
    dark: true,
    themes: {
      light: {
        primary: "#ee44aa",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    values: {
      linkedin: { component: linkedin },
      email: { component: email },
      twitter: { component: twitter },
      link: { component: link },
      techs: { component: techs },
      git: { component: git },
      stackoverflow: { component: stackoverflow },
      close: { component: close },
      moon: { component: moon },
      github: { component: github },
      blog: { component: blog },
    },
  },
});
